import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'globalthis/auto'

import '@vant/touch-emulator';
import FastClick from 'fastclick'
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
import zhTW from 'vant/es/locale/lang/zh-TW';
import { Locale } from 'vant';
import "@/assets/sdk.js"
import { RadioGroup, Radio } from 'vant';
import { Grid, GridItem,Button,Uploader,Dialog } from 'vant';
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Button);
Vue.use(Uploader);
Vue.use(Dialog);

if(localStorage.getItem('locale') == 'en'){
  Locale.use('en-US', enUS);
} else {
  Locale.use('zh-TW', zhTW);
}
let vuethis;


Vue.config.productionTip = false

import './assets/css/common.less';
import './assets/fonts/iconfont.css';
import 'lib-flexible/flexible'

import i18n from './i18n/index'
import VueClipboard from 'vue-clipboard2'
import TawkMessenger from "@/utils/tawk";
Vue.use(VueClipboard)
Vue.prototype.i18n = i18n
// 定义一个全局函数
Vue.prototype.$tawk = function() {
  let options = {};
  options.propertyId = window.localStorage.getItem('propertyId');
  options.widgetId = window.localStorage.getItem('widgetId');
  console.log("options:",options)
  let tk = new TawkMessenger(vuethis, options);
  Vue.prototype.$tawkMessenger = vuethis;
  // 在这里编写全局函数的逻辑
};

FastClick.prototype.focus = function(targetElement) {
  var length;
  var deviceIsWindowsPhone = navigator.userAgent.indexOf("Windows Phone") >= 0;
  var deviceIsIOS = /iP(ad|hone|od)/.test(navigator.userAgent) && !deviceIsWindowsPhone;
  //兼容处理:在iOS7中，有一些元素（如date、datetime、month等）在setSelectionRange会出现TypeError
  //这是因为这些元素并没有selectionStart和selectionEnd的整型数字属性，所以一旦引用就会报错，因此排除这些属性才使用setSelectionRange方法
  if (deviceIsIOS && targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month' && targetElement.type !== 'email') {
    length = targetElement.value.length;
    targetElement.setSelectionRange(length, length); //修复bug ios 11.3不弹出键盘，这里加上聚焦代码，让其强制聚焦弹出键盘
    targetElement.focus();
  } else {
    targetElement.focus();
  }
}
// 配置错误收集
// import Bugsnag from '@bugsnag/js'
// import BugsnagPluginVue from '@bugsnag/plugin-vue'
//
// Bugsnag.start({
//   apiKey: 'c189a799f01edc958db0edcb0eefd3c9',
//   plugins: [new BugsnagPluginVue()]
// })
//
// const bugsnagVue = Bugsnag.getPlugin('vue')
// bugsnagVue.installVueErrorHandler(Vue)
// Bugsnag.notify(new Error('Test error'))
// 配置全局错误处理器
Vue.config.errorHandler = (err, vm, info) => {
  // 发送错误日志到服务端
  sendErrorToServer({
    message: err.message,
    stack: err.stack,
    component: vm.$options.name,
    info: info
  });
};

window.addEventListener('unhandledrejection', (event) => {
  sendErrorToServer({
    message: event.reason.message,
    stack: event.reason.stack
  });
});

window.addEventListener('error', (event) => {
  sendErrorToServer({
    message: event.message,
    stack: event.error ? event.error.stack : null,
    source: event.filename,
    line: event.lineno,
    column: event.colno
  });
});
// 在函数外部定义 errorCache，用 Set 存储已记录的错误信息
const errorCache = new Set();
function sendErrorToServer(errorInfo) {
  // 检查是否为“避免重复导航到当前路径”的错误，如果是则跳过
  // 检查是否为需要过滤的错误
  if (
      errorInfo.message &&
      (
          errorInfo.message.includes("Avoided redundant navigation to current location") ||
          errorInfo.message.includes("Request failed with status code 429") ||
          errorInfo.message.includes("Navigation cancelled") ||
          errorInfo.message.includes("Request failed with status code 403")
      )
  ) {
    return;
  }
  const errorKey = `${errorInfo.message}-${errorInfo.stack}`;
  if (errorCache.has(errorKey)) return; // 已发送相同错误，不再重复发送

  errorCache.add(errorKey); // 将错误加入缓存
  setTimeout(() => errorCache.delete(errorKey), 60000); // 一分钟后移出缓存

  fetch('/api/mobile/error-log', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(errorInfo)
  }).catch(error => {
    console.error('Error logging to server:', error);
  });
}
vuethis = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
